<template>
  <div id="app">

    <header>
      <div class="header-wrap">
        <div class="header-logo">百旺物联网</div>
        <nav class="header-nav">
          <router-link @click.native="linkClick($event)" to="/home" class="header-nav-item">首页</router-link>
          <router-link @click.native="linkClick($event)" to="/IndustryIOT" class="header-nav-item">工业物联网</router-link>
          <router-link @click.native="linkClick($event)" to="/Product" class="header-nav-item">产品</router-link>
          <router-link @click.native="linkClick($event)" to="/SuccessfulCase" class="header-nav-item">应用案例</router-link>
          <router-link @click.native="linkClick($event)" to="/Download" class="header-nav-item">资料下载</router-link>
          <router-link @click.native="linkClick($event)" to="/About" class="header-nav-item">关于我们</router-link>
<!--          <router-link @click.native="linkClick($event)" to="/YourMessage" class="header-nav-item">在线留言</router-link>-->
          <router-link @click.native="linkClick($event)" to="/Shop" class="header-nav-item header-nav-item-button">立即购买</router-link>
          <b-link style="margin-left:20px;color: #68bcf0" href="http://bwtrans.baiwangjs.com:8066/login">平台登陆</b-link>
        </nav>
      </div>
    </header>
    <router-view class="center" @Introduce ="showIntroduce"></router-view>
    <footer>
      <div>
          <p>Copyright © 2016-2020 河南航天金穗电子有限公司 &nbsp;保留所有权利 &nbsp;Copyright。</p>
          <p>郑州工商靓照 豫ICP备05011117号</p>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
  data(){
    return{
      introduce:{
        ptext:"",
        spantext:"",
        imageurl:"",
      },
      pics:[
        { url: require("../static/images/material/1008.jpg") },
        { url: require("../static/images/material/1009.png") },
      ],
      currentPostion:"",
    }
  },
  created() {
    console.log("1234")
  },
  methods:{
    showIntroduce:function(val){
      this.introduce.ptext = val.ptext;
      this.introduce.spantext = val.spantext;
    },
    linkClick:function(event){
      var el = event.currentTarget;
      var elText = el.innerHTML
      this.currentPostion = el.innerHTML
      console.log(el)
      switch (elText) {
        case "首页":
          break;
      }
    }
  }
}
</script>

<style>
  #app {
    margin-top: -10px;
  }
</style>
