import Vue from 'vue'
import Vuex from 'vuex'
var Fs = require('fs');
// import Fs from  'fs'
Vue.use(Vuex)
// const downfilepath = "http://bwtrans.baiwangjs.com/pollux/"
// const descriptin = [
//     {
//         文件名称:"网关应用软件开发计划图",
//         文件说明:"网关应用软件开发计划图",
//         文件大小:"120k",
//         文件下载:downfilepath+ "网关应用软件开发计划图.eddx"
//     },
//     {
//         文件名称:"网关应用程序开发框架导图",
//         文件说明:"网关应用程序开发框架导图",
//         文件大小:"120k",
//         文件下载:"http://127.0.0.1:8001/pollux/网关应用程序开发框架导图.xmind"
//     },
//     {
//         文件名称:"工业网关配置服务接口文档说明",
//         文件说明:"工业网关配置服务接口文档说明",
//         文件大小:"120k",
//         文件下载:"http://127.0.0.1:8001/pollux/工业网关配置服务接口文档说明.doc"
//     },
//     {
//         文件名称:"工业网关数据库设计手册",
//         文件说明:"工业网关数据库设计手册",
//         文件大小:"120k",
//         文件下载:"http://127.0.0.1:8001/pollux/工业网关数据库设计手册.docx"
//     }
// ]

var downfilePath = "/opt/iot/xjgw/dist/download/file/"
function GetFileRes() {
    var files = []//Fs.readdirSync(downfilePath);
    var filelist = []
    for(var index in files)
    {
        var fname = files[index]
        var filepath = downfilePath + files[index];
        var filist = Fs.statSync(filepath)
        var filesize = Math.round(filist.size/1024)
        var fileInfo = {
            文件名称:fname,
            文件说明:fname,
            文件大小:filesize,
            文件下载:fname
        }
        filelist.push(fileInfo)
    }
    // console.log(filelist)
    return filelist
}
const descriptin = GetFileRes()
const images = {
    picsum_photos_01:"https://picsum.photos/1024/360/?image=54",
    picsum_photos_02:"https://picsum.photos/1024/360/?image=55",
    picsum_photos_04:"https://picsum.photos/1024/300/?image=10",
    picsum_photos_05:"https://picsum.photos/1024/360/?image=12",
    picsum_photos_06:"https://picsum.photos/1024/360/?image=22",
    picsum_photos_07:"https://picsum.photos/900/250/?image=3",
    picsum_photos_08:"https://picsum.photos/1024/360/?image=52",
    picsum_photos_09:"https://picsum.photos/1024/360/?image=54",
    picsum_photos_10:"https://picsum.photos/1024/360/?image=58",
    picsum_photos_11:"https://picsum.photos/1024/360/?image=55",
    material_1001:require("../../static/images/material/1001.jpg"),
    material_zhjt:require("../../static/images/material/zhjt.png"),
    material_qzhy:require("../../static/images/material/qzhy.png"),
    material_qzhy1:require("../../static/images/material/qzhy1.jpg"),
    material_gywlw:require("../../static/images/material/gywlw.jpg"),
    material_gywlw1:require("../../static/images/material/gywlw1.png"),
    material_baiwangjs:require("../../static/images/material/baiwangjs.jpg"),
    material_wlw:require("../../static/images/material/wlw.jpg"),
    material_ngjk:require("../../static/images/material/ngjk.jpg"),
    material_zhuye:require("../../static/images/material/zhuye.jpg"),
    material_jrx:require("../../static/images/material/兼容性.png"),
    material_kfx:require("../../static/images/material/开放性.png"),
    material_kpz:require("../../static/images/material/可配置.png"),
    material_kpt:require("../../static/images/material/跨平台.png"),
    material_aqx:require("../../static/images/material/安全性.png"),
    scene_01_yqktjk:require("../../static/images/scene/01/园区空调监控.png"),
    scene_01_bsfujk:require("../../static/images/scene/01/办税终端监控.png"),
    scene_02_wsjk:require("../../static/images/scene/02/温室监控.png"),
    product_axl:require("../../static/images/product/A系列.png"),
    product_lxl:require("../../static/images/product/L系列.png"),
    product_mxl:require("../../static/images/product/M系列.png"),
    product_sxl:require("../../static/images/product/S系列.png"),
    product_wxl:require("../../static/images/product/W系列.png"),
    product_xhggqd:require("../../static/images/product/型号规格清单.png"),
    product_cpjkjs:require("../../static/images/product/产品接口介绍.png"),
    product_cpmkjs:require("../../static/images/product/产品模块介绍.png"),
    publicize_01_3:require("../../static/images/publicize/01/3.png"),
    publicize_01_5:require("../../static/images/publicize/01/5.png"),
    publicize_01_6:require("../../static/images/publicize/01/6.png"),
    publicize_01_7:require("../../static/images/publicize/01/7.png"),
    publicize_01_1:require("../../static/images/publicize/01/1.png"),
    publicize_01_2:require("../../static/images/publicize/01/2.png"),
    publicize_03_6:require("../../static/images/publicize/03/6.png"),
    other_yyyd:require("../../static/images/other/应用优点.png"),
}
const state = {
    descriptin,
    images,
}
export default new Vuex.Store(
    {
        state
    }
)