<template>
    <div>

        <div>
            <div>
                <b-card-group deck>
                    <b-card :img-src="$store.state.images.publicize_01_1" img-alt="Card image" img-top>
                        <b-card-text>
                        </b-card-text>
                    </b-card>

                    <b-card :img-src="$store.state.images.publicize_01_2" img-alt="Card image" img-top>
                        <hr>
                        <b-card-text>
                            联系我们:
                        </b-card-text>
                        <b-card-text>
                            服务电话:0371-61622501
                        </b-card-text>
                        <b-card-text>
                            邮箱:258356955@QQ.COM
                        </b-card-text>
                        <b-card-text>
                            地址:河南省郑州市管城回族区金岱产业集聚区文治路13号金穗软件园2层201
                        </b-card-text>
                    </b-card>
                </b-card-group>
            </div>
        </div>

        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: "About",
        data(){
            return{
            }
        },
        created() {
            var introduce = {
                ptext:"百旺金穗云信息科技有限公司是一家优秀的IT高科技企业",
                spantext:"Baiwang jinsuiyun Information Technology Co., Ltd. is an excellent IT high-tech enterpris",
            }
            this.$emit('Introduce',introduce);
        }
    }
</script>

<style scoped>

</style>