<template>
    <div>
        <b-table striped hover :items="items" :fields="fields">
        </b-table>
        <div>
            <b-card-group deck>
                <b-card title="" :img-src="$store.state.images.product_xhggqd" img-alt="Image" img-top >
                    <b-card-text>
                    </b-card-text>
                </b-card>
            </b-card-group>
        </div>
<!--        <p>{{list}}</p>-->
        <router-view></router-view>
    </div>
</template>

<script>
    import Axios from 'axios';
    // Axios.defaults.withCredentials = true
    function getattr(attrs,attr) {
        var value = ''
        for(var k=0,length=attrs.length;k<length;k++){
            if(attrs[k].attrkey == attr)
            {
                value = attrs[k].AttributeValue;
                break;
            }
        }
        return value
    }
    function formatDate(date1) {
        var date = new Date(date1);
        var YY = date.getFullYear() + '-';
        var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
        var hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        var mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        var ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
        return YY + MM + DD +" "+hh + mm + ss;
    }
    function MillisecondToDate(mss){
        /* eslint-disable */
        let date='';
        const days = parseInt(mss / (1000 * 60 * 60 * 24));
        const hours = parseInt((mss % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
        // const seconds = (mss % (1000 * 60)) / 1000;
        const seconds = parseInt((mss % (1000 * 60)) / 1000);
        if(days>0){
            date+=`${days} 天`;
        }
        if(hours>0){
            date+=`${hours} 时`;
        }
        if(minutes>0){
            date+=`${minutes} 分`;
        }
        if(seconds>0){
            date+=`${seconds} 秒`;
        }
        /* eslint-enable */
        return date;
    }
    export default {
        name: "iot.vue",
        data(){
            return{
                fields:['编号','型号','生产日期','在线状态','离线时长','设备IP','硬件版本号','软件版本号','设备CID'],
                items: [
                    {
                        '编号':"未登记",
                        '型号':"无",
                        '生产日期':"无",
                        '在线状态':"离线",
                        '离线时长':"0",
                        '设备IP':"无",
                        '硬件版本号':"无",
                        '软件版本号':'无',
                        '设备CID':'无'
                    }
                ],
                list: []
            }
        },
        created() {
          console.log(this.$route.query);
          if(this.$route.query['sn'] == undefined)
          {
              this.items[0].编号 = "编号未找到";
          }else
          {

              if(this.$route.query.sn != "")
              {
                  this.items[0].编号 = this.$route.query.sn;

                  // var api = "http://127.0.0.1:8001/target/info?sn=" + this.$route.query.sn;
                  // var api = "http://10.10.10.72:8001/target/info?sn=" + this.$route.query.sn;
                  var api = "http://bwtrans.baiwangjs.com/apibackend/target/info?sn=" + this.$route.query.sn;
                  Axios.get(api).then((response)=>{
                      this.list=response.data;
                      // this.items[0].型号  = getattr(response.data.data.attrs,"model")
                      this.items[0].生产日期  = formatDate(response.data.data.createid)
                      this.items[0].设备IP  = getattr(response.data.data.attrs,"eth0_IP")
                      this.items[0].硬件版本号  = getattr(response.data.data.attrs,"HW")
                      this.items[0].软件版本号  = getattr(response.data.data.attrs,"SW")
                      this.items[0].设备CID  = getattr(response.data.data.attrs,"CID")
                      // response.data.data.lasttargetquery
                      var timestamp3 =new Date().getTime();
                      console.log(timestamp3,response.data.data.lasttargetquery)

                      if(response.data.data.lasttargetquery + 360000 > timestamp3){
                          this.items[0].在线状态 = "在线"
                      }else{
                          var timval = timestamp3 - response.data.data.lasttargetquery - 360000
                          this.items[0].离线时长 = MillisecondToDate(timval)
                      }
                  }).catch((error)=>{
                      console.log(error);
                  })
              }else
              {
                  this.items[0].编号 = "未查到此编号"
              }
          }
        }
    }
</script>

<style scoped>

</style>