<template>
    <div>
        <dv-full-screen-container>content</dv-full-screen-container>
    </div>
</template>

<script>
    export default {
        name: "Project.vue"
    }
</script>

<style scoped>

</style>