<template>
    <div>
        <b-table :items="descriptin" :fields="fields">
            <template v-slot:cell(文件下载)="download">
                <a :href="download.value">下载</a>
            </template>
        </b-table>
        <el-table
                :data="descriptin"
                border
                style="width: 100%"
                max-height="250">
            <el-table-column
                    fixed
                    prop="filename"
                    label="文件名称"
                    width="220">
            </el-table-column>
            <el-table-column
                    prop="illustrate"
                    label="文件说明"
                    width="220"
                    show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                    prop="size"
                    label="文件大小"
                    width="120">
            </el-table-column>
            <el-table-column
                    prop="download"
                    label="下载"
                    width="120">
                <template slot-scope="scope">
                    <a :href="scope.row.download">下载</a>
                </template>

            </el-table-column>
        </el-table>
        <router-view></router-view>
    </div>
</template>

<script>
    import Axios from 'axios';
    export default {
        name: "Download",
        data(){
            return{
                msg:"资料下载",
                dataarea:[],
                descriptin:[],
                fields: ['文件名称', '文件说明', '文件大小','文件下载'],
                items: [
                    { age: 40, 文件名称: 'Dickerson', last_name: 'Macdonald' },
                    { age: 21, 文件名称: 'Larsen', last_name: 'Shaw' },
                    { age: 89, 文件名称: 'Geneva', last_name: 'Wilson' }
                ]
            }
        },
        created() {
            var introduce = {
                ptext:"我们提供了丰富的文档手册及接口手册,为您的使用带来更多的便利",
                spantext:"We provide rich documentation manuals and interface manuals to bring you more convenience",
            }
            this.$emit('Introduce',introduce);
            var api = "http://bwtrans.baiwangjs.com/files/all";
            Axios.get(api).then((response)=>{
                this.descriptin=response.data;
            }).catch((error)=>{
                console.log(error);
            })
        }
    }
</script>

<style scoped>

</style>