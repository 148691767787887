<template>
    <div>
        <div>
            <b-carousel
                    id="carousel-fade"
                    style="text-shadow: 0px 0px 2px #000"
                    fade
                    indicators
                    img-width="1024"
                    img-height="360"
            >
                <b-carousel-slide
                        caption=""
                        :img-src="$store.state.images.picsum_photos_04"
                ></b-carousel-slide>
                <b-carousel-slide
                        caption=""
                        :img-src="$store.state.images.picsum_photos_05"
                ></b-carousel-slide>
                <b-carousel-slide
                        caption=""
                        :img-src="$store.state.images.picsum_photos_06"
                ></b-carousel-slide>
            </b-carousel>
        </div>

        <div style="text-align: center;margin-top: 20px;padding: 30px 30px 30px 30px">
            <h1>借助工业 IOT (IIOT) 合理利用您的数据</h1>
            <p>物联网云平台通过互联网(以太网 4G WIFI LOAR等)通讯技术,将现场大量不同地域工业设备的数据或者程序,传输到远端云数据中心,
                实现远程数据监控,设备诊断,程序维护和故障报警等功能, 为用户提供一种简单可靠的工业互联网数据远程传输方案
            </p>
        </div>
        <div>
            <b-card
                    overlay
                    :img-src="$store.state.images.picsum_photos_07"
                    img-alt="Card Image"
                    text-variant="white"
                    title="工业物联网"
                    sub-title="智慧云平台"
            >
                <b-card-text>
                    借助于互联网云平台应用,接入各种工业传感器采集设备,可实现系统的远程数据采集及监控
                </b-card-text>
            </b-card>
        </div>
        <div class="BWCould " style="background-color: #ffffff;height: 500px">
            <h2 style="text-align: center;margin: 30px;">产品特点</h2>
            <div class="center">
                <div class="overall-scheme-list">
                    <div class="overall-scheme-list-top">
                        <img :src="$store.state.images.material_jrx">
                    </div>
                    <div class="overall-scheme-list-middle">
                        开放性
                    </div>
                    <div class="overall-scheme-list-bottom">
                        在解决方案各处可以对接第三方平台,例如物联网终端、连接平台等。
                    </div>
                </div>
                <div class="overall-scheme-list">
                    <div class="overall-scheme-list-top">
                        <img :src="$store.state.images.material_kpz">
                    </div>
                    <div class="overall-scheme-list-middle">
                        可配置
                    </div>
                    <div class="overall-scheme-list-bottom">
                        组态界面可配置，数据指标可 配置，采集规则可配置，转发 规则可配置，大部分采用拖拽 式配置能力，部分需要脚本语 言做配置。
                    </div>
                </div>
                <div class="overall-scheme-list">
                    <div class="overall-scheme-list-top">
                        <img :src="$store.state.images.material_kfx">
                    </div>
                    <div class="overall-scheme-list-middle">
                        安全性
                    </div>
                    <div class="overall-scheme-list-bottom">
                        使用安全通讯硬件方案及对称加密算法,保证数据传输通道的安全性。
                    </div>
                </div>
                <div class="overall-scheme-list">
                    <div class="overall-scheme-list-top">
                        <img :src="$store.state.images.material_kpt">
                    </div>
                    <div class="overall-scheme-list-middle">
                        跨平台
                    </div>
                    <div class="overall-scheme-list-bottom">
                        支持跨平台能力，在大屏，电脑，手机APP端均可友好的访问服务。
                    </div>
                </div>
                <div class="overall-scheme-list">
                    <div class="overall-scheme-list-top">
                        <img :src="$store.state.images.material_jrx">
                    </div>
                    <div class="overall-scheme-list-middle">
                        兼容性
                    </div>
                    <div class="overall-scheme-list-bottom">
                        支持300+工业协议。
                    </div>
                </div>
            </div>
        </div>
        <div>
            <b-card-group deck>
                <b-card title="" :img-src="$store.state.images.other_yyyd" img-alt="Image" img-top >
                    <b-card-text>
                    </b-card-text>
                </b-card>
            </b-card-group>
        </div>
        <div style="text-align: center">
            <b-jumbotron header="百旺金穗云.物联网" lead="多协议支持\开发的数据接口\分布式">
                <p>支持多种行业应用</p>
<!--                <b-button variant="primary" href="#">了解更多</b-button>-->
            </b-jumbotron>
        </div>
        <div>
            <b-carousel
                    id="carousel-1"
                    v-model="slide"
                    :interval="4000"
                    controls
                    indicators
                    background="#ababab"
                    img-width="1024"
                    img-height="360"
                    style="text-shadow: 1px 1px 2px #333;"
                    @sliding-start="onSlideStart"
                    @sliding-end="onSlideEnd"
            >

                <!-- Text slides with image -->
                <b-carousel-slide
                        caption="智慧工业"
                        text="Nulla vitae elit libero, a pharetra augue mollis interdum."
                        :img-src="$store.state.images.picsum_photos_08"
                ></b-carousel-slide>

                <!-- Slides with custom text -->
                <b-carousel-slide :img-src="$store.state.images.picsum_photos_09">
                    <h1>百旺物联网!</h1>
                </b-carousel-slide>

                <!-- Slides with image only -->
                <b-carousel-slide :img-src="$store.state.images.picsum_photos_10"></b-carousel-slide>

                <!-- Slides with img slot -->
                <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
                <b-carousel-slide>
                    <template v-slot:img>
                        <img
                            class="d-block img-fluid w-100"
                            width="1024"
                            height="480"
                            :src="$store.state.images.picsum_photos_11"
                            alt="image slot"
                        >
                    </template>
                </b-carousel-slide>

                <!-- Slide with blank fluid image to maintain slide aspect ratio -->
                <b-carousel-slide caption="Blank Image" img-blank img-alt="Blank image">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse eros felis, tincidunt
                        a tincidunt eget, convallis vel est. Ut pellentesque ut lacus vel interdum.
                    </p>
                </b-carousel-slide>
            </b-carousel>

<!--            <p class="mt-4">-->
<!--                Slide #: {{ slide }}<br>-->
<!--                Sliding: {{ sliding }}-->
<!--            </p>-->
        </div>
        <div style="margin-top: 50px;">
            <b-jumbotron bg-variant="info" text-variant="white" border-variant="dark">
                <template v-slot:header>行业应用</template>
                <template v-slot:lead>
                    环保 智能电力 暖通空调 智慧交通.
                </template>
                <hr class="my-4">
                <p>
                    Environmental protection intelligent power HVAC intelligent transportation.
                </p>
            </b-jumbotron>
        </div>
        <div class="mt-4">
            <h4>
                环保行业
            </h4>
            <b-card :img-src="$store.state.images.material_1001" img-alt="Card image" img-left class="mb-3">
                <b-card-text>
                    各省环保厅建设的重点污染源监控，国家对重点河流断面的水质监测工作，
                    各主要城市、地市空气质量自动监测系统，排污权交易工作的开展等等
                </b-card-text>
            </b-card>
            <h4>
                智慧交通
            </h4>
            <b-card :img-src="$store.state.images.material_zhjt" img-alt="Card image" img-right>
                <b-card-text>
                    在路侧单元中部署边缘计算框架、完成路况处理等AI自动驾驶应用，将计算能力从车辆转移到路侧单元,
                    降低自动驾驶车辆的成本,发挥AIOT在车路协同场景中应用分发、可靠连接的优势
                </b-card-text>
            </b-card>
        </div>
        <b-card-group deck>
            <h4>
                工业起重行业
            </h4>
            <b-card :img-src="$store.state.images.material_qzhy1" img-alt="Card image" img-bottom>
                <b-card-text>
                    塔吊安全监控系统从建筑施工塔吊安装到完全拆除为止的 生命周期当中，通过信息化手段进行塔吊运行全过程监控记录，安装拆除 过程防倾覆控制，群塔防碰撞的一整套监控系统，是对塔吊一个生命周期 的全过程监控，从而达到安全生产的根本目标
                </b-card-text>
            </b-card>
        </b-card-group>
        <b-card bg-variant="dark" text-variant="white" title="工业智慧产品">
            <b-card-text>
                涉及工业应用接入网关,起重智能监控产品.
            </b-card-text>
            <b-button href="#" variant="primary">>>>了解更多</b-button>
        </b-card>
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: "Home",
        data(){
            return{
            }
        },
        created() {
            var introduce = {
                ptext:"百旺金穗云工业产品",
                spantext:"公司拥有自主知识产品品牌，致力于优质的工业物联网产品开发与应用",
            }
            this.$emit('Introduce',introduce);
        }
    }
</script>

<style scoped>
    .wrapper {
        flex: 1;
        display:flex;
        /*border: 1px solid blue;*/
    }
    .left, .middle, .right {
        flex: 1;
        display:flex;
        flex-flow: column;
        padding: 5px 0;
    }
    .cl {
        /*border: 1px solid pink;*/
        flex: 1;
        margin: 5px;
    }
    img{
        border-radius: 8px;
    }
    a:hover{
        background-color:yellow;
        box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
    }
</style>