<template>
    <div>
        <div>
            <b-jumbotron>
                <template v-slot:header>XJGW物联网网关</template>

                <template v-slot:lead>
                    高性能/高稳定性
                </template>
                <hr class="my-4">
                <p>
                    工业物联网网关是在本地部署的工业计算机,用于收集、存储、处理和分析网络边缘的数据,减轻对云和数据中心的压力。物联网网关通常从传感器、设备和机器收集数据。物联网网关收集数据后,对数据进行处理和分析,将处理后的数据发送到云端进行远程监控、远程控制和后期分析。
                </p>
                <b-button variant="primary" href="#">>>>了解详情</b-button>
            </b-jumbotron>
        </div>
        <div>
            <b-card-group deck>
                <b-card title="" :img-src="$store.state.images.publicize_01_3" img-alt="Image" img-top >
                    <b-card-text>
                    </b-card-text>
                </b-card>
                <b-card title="" :img-src="$store.state.images.publicize_01_5" img-alt="Image" img-top >
                    <b-card-text>
                    </b-card-text>
                </b-card>
                <b-card title="" :img-src="$store.state.images.product_cpmkjs" img-alt="Image" img-bottom >
                    <b-card-text>
                        产品模块介绍
                    </b-card-text>
                </b-card>
                <b-card title="" :img-src="$store.state.images.product_cpjkjs" img-alt="Image" img-bottom >
                    <b-card-text>
                        产品接口介绍
                    </b-card-text>
                </b-card>
                <b-card title="XJGW-100-A" :img-src="$store.state.images.product_xhggqd" img-alt="Image" img-top >
                    <b-card-text>
                        功能特点:
                    </b-card-text>
                    <b-card-text>
                        1.一路RS232/RS485/RS422,两路can工业总线<br/>
                        2.二路以太网接口<br/>
                        3.支持4G无线通讯<br/>
                        4.支持lora无线通讯<br/>
                    </b-card-text>

                    <template v-slot:footer>
                        <small class="text-muted">>>>购买</small>
                    </template>
                </b-card>
            </b-card-group>
        </div>

        <router-view></router-view>

    </div>
</template>

<script>
    export default {
        name: "Product",
        data(){
            return{
                currentDate:"123",
            }
        },
        created() {
            var introduce = {
                ptext:"主要产品包含工业智能网关产品、智能电力仪表、税务自助终端、公安自助终端、盘组服务器等",
                spantext:"The main products include industrial intelligent gateway products, intelligent power meters, tax self-service terminals, public security self-service terminals, panel servers, etc",
            }
            this.$emit('Introduce',introduce);
        }
    }
</script>

<style scoped>
</style>