import Vue from 'vue'
import './assets/mystyle.css'
import './assets/main.css'

import dataV from '@jiaminghi/data-view'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import AFTableColumn from 'af-table-column'
import App from './App.vue'
import router from './router/router'
import store from './store/store'


Vue.config.productionTip = false
Vue.use(AFTableColumn)
Vue.use(BootstrapVue)
Vue.use(dataV)

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
