<template>
    <div>
<!--        <div class="slide">-->
<!--            <ul class="slide-auto">-->
<!--                <li>one</li>-->
<!--                <li>two</li>-->
<!--                <li>three</li>-->
<!--            </ul>-->
<!--        </div>-->
        <div>
            <div>
                <b-form-group
                        id="fieldset-horizontal"
                        label-cols-sm="4"
                        label-cols-lg="3"
                        description="Let us know your name."
                        label="Enter your name"
                        label-for="input-horizontal"
                >
                    <b-form-input id="input-horizontal"></b-form-input>
                </b-form-group>
            </div>
        </div>
<!--        <form action="" method="post" class="smart-green">-->
<!--            <h1>Contact Form-->

<!--                <span>Please fill all the texts in the fields.</span>-->
<!--            </h1>-->
<!--            <label>-->
<!--                <span>Themplate :</span>-->
<!--                <select id="template" name="themplate" onchange="changeStyle();">-->
<!--                    <option value="basic-grey">basic-grey</option>-->
<!--                    <option value="elegant-aero">elegant-aero</option>-->
<!--                    <option value="smart-green">smart-green</option>-->
<!--                    <option value="white-pink">white-pink</option>-->
<!--                    <option value="bootstrap-frm">bootstrap-frm</option>-->
<!--                    <option value="dark-matter">dark-matter</option>-->
<!--                </select>-->
<!--            </label>-->
<!--            <label>-->
<!--                <span>Your Name :</span>-->
<!--                <input id="name" type="text" name="name" placeholder="Your Full Name" />-->
<!--            </label>-->
<!--            <label>-->
<!--                <span>Your Email :</span>-->
<!--                <input id="email" type="email" name="email" placeholder="Valid Email Address" />-->
<!--            </label>-->
<!--            <label>-->
<!--                <span>Message :</span>-->
<!--                <textarea id="message" name="message" placeholder="Your Message to Us"></textarea>-->
<!--            </label>-->
<!--            <label>-->
<!--                <span>Subject :</span><select name="selection">-->
<!--                <option value="Job Inquiry">Job Inquiry</option>-->
<!--                <option value="General Question">General Question</option>-->
<!--            </select>-->
<!--            </label>-->
<!--            <label>-->
<!--                <span>&nbsp;</span>-->
<!--                <input type="button" class="button" value="Send" />-->
<!--            </label>-->
<!--        </form>-->
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: "YourMessage",
        data(){
            return{

            }
        },
        created() {
            var introduce = {
                ptext:"期待您的留言,我们致力于优质的服务",
                spantext:"Looking forward to your message, we are committed to high-quality service"
            }
            this.$emit('Introduce',introduce);
        }
    }
</script>

<style scoped>
    div{
        background-color: #ccc4d6;
    }
    .smart-green {
        margin-left:auto;
        margin-right:auto;
        max-width: 500px;
        background: #F8F8F8;
        padding: 30px 30px 20px 30px;
        font: 12px Arial, Helvetica, sans-serif;
        color: #666;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
    }
    .smart-green h1 {
        font: 24px "Trebuchet MS", Arial, Helvetica, sans-serif;
        padding: 20px 0px 20px 40px;
        display: block;
        margin: -30px -30px 10px -30px;
        color: #FFF;
        background: #9DC45F;
        text-shadow: 1px 1px 1px #949494;
        border-radius: 5px 5px 0px 0px;
        -webkit-border-radius: 5px 5px 0px 0px;
        -moz-border-radius: 5px 5px 0px 0px;
        border-bottom:1px solid #89AF4C;
    }
    .smart-green h1>span {
        display: block;
        font-size: 11px;
        color: #FFF;
    }
    .smart-green label {
        display: block;
        margin: 0px 0px 5px;
    }

    .smart-green label>span {
        float: left;
        margin-top: 10px;
        color: #5E5E5E;
    }
    .smart-green input[type="text"], .smart-green input[type="email"], .smart-green textarea, .smart-green select {
        color: #555;
        height: 30px;
        line-height:15px;
        width: 100%;
        padding: 0px 0px 0px 10px;
        margin-top: 2px;
        border: 1px solid #E5E5E5;
        background: #FBFBFB;
        outline: 0;
        -webkit-box-shadow: inset 1px 1px 2px rgba(238, 238, 238, 0.2);
        box-shadow: inset 1px 1px 2px rgba(238, 238, 238, 0.2);
        font: normal 14px/14px Arial, Helvetica, sans-serif;
    }
    .smart-green textarea{
        height:100px;
        padding-top: 10px;
    }
    .smart-green select {
        /*background: url('../assets/logo.png') no-repeat right, -moz-linear-gradient(top, #FBFBFB 0%, #E9E9E9 100%);*/
        /*background: url('../assets/logo.png') no-repeat right, -webkit-gradient(linear, left top, left bottom, color-stop(0%,#FBFBFB), color-stop(100%,#E9E9E9));*/
        appearance:none;
        -webkit-appearance:none;
        -moz-appearance: none;
        text-indent: 0.01px;
        text-overflow: '';
        width:100%;
        height:30px;
    }
    .smart-green .button {
        background-color: #9DC45F;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-border-radius: 5px;
        border: none;
        padding: 10px 25px 10px 25px;
        color: #FFF;
        text-shadow: 1px 1px 1px #949494;
    }
    .smart-green .button:hover {
        background-color:#80A24A;
    }
</style>