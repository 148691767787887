import Vue from "vue"
import VueRouter from 'vue-router'

import Nav from '@/components/Nav.vue'
import Home from '@/components/Home.vue'
import IndustryIOT from '@/components/IndustryIOT.vue'
import Product from '@/components/Product.vue'
import Download from '@/components/Download.vue'
import SuccessfulCase from '@/components/SuccessfulCase.vue'
import About from '@/components/About.vue'
import YourMessage from '@/components/YourMessage.vue'
import Shop from '@/components/Shop.vue'
import api from '@/components/api.vue'
import iot from '@/components/api/iot.vue'
import Prj from '@/components/datav/Project.vue'
Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        redirect: {
            name:'Home'
        },
        component: Nav
    },
    {
        path: '/Home',
        name:'Home',
        component: Home,
        meta:{
            title:"主页"
        }
    },
    {
        path: '/IndustryIOT',
        name:'IndustryIOT',
        component: IndustryIOT,
        meta:{
            title:"工业物联网"
        }
    },
    {
        path: '/Product',
        name:'Product',
        component: Product,
        meta:{
            title:"产品介绍"
        }
    },
    {
        path: '/Download',
        name:'Download',
        component: Download,
        meta:{
            title:"资料下载"
        }
    },
    {
        path: '/About',
        name:'About',
        component: About,
        meta:{
            title:"关于我们"
        }
    },
    {
        path: '/SuccessfulCase',
        name:'SuccessfulCase',
        component: SuccessfulCase,
        meta:{
            title:"成功案例"
        }
    },
    {
        path: '/Shop',
        name:'Shop',
        component: Shop,
        meta:{
            title:"购买"
        }
    },
    {
        path: '/YourMessage',
        name:'YourMessage',
        component: YourMessage,
        meta:{
            title:"留言板"
        }
    },
    {
        path: '/api',
        name:'api',
        component: api,
        meta:{
            title:"api"
        },
        children: [{
            path: 'iot',
            name: 'api',
            component: iot,
            meta:{
                title:"设备信息"
            }
        }]
    },
    {
        path: 'Prj',
        name: 'Prj',
        component: Prj,
        meta:{
            title: '大屏展示'
        }
    }
]
var router =  new VueRouter({
    routes,
    mode: 'history'
})
export default router;