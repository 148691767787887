<template>
    <div>
        <div>
            <div>
                <b-card-group deck>
                    <b-card :img-src="$store.state.images.publicize_01_5" img-alt="Card image" img-bottom>
                        <b-card-text>
                            一，工业网关在农业领域的应用：农业大棚环境监测信号采集、数据传输，实现农业大棚的智能化管理；另一方面是对养殖环境监测信号采集、数据传输，畜牧养殖现场的数据采集以及相关设备的远程监控。
                        </b-card-text>
                        <b-card-text>
                            二，工业制造领域的运用：生产制造、加工企业生产过程信号采集、数据传输;工业智能生产线MES(制造执行系统)信号采集、数据传输;
                        </b-card-text>
                        <b-card-text>
                            三、相关市政项目的应用：市政供、排水管网、管沟监测;地下管廊环境监测;危化场所环境监测，大气环境监测信号采集、数据传输。
                        </b-card-text>
                        <b-card-text>
                            四、仓储物流行业的应用：随着网购事业的发展壮大，物流行业要求也越来越高，实时监控相关包裹运行的动态，追踪其运行途径。医药、食品仓储环境监测信号采集、数据传输。
                        </b-card-text>
                    </b-card>
                    <b-card :img-src="$store.state.images.scene_02_wsjk" img-alt="Card image" img-top>
                        <b-card-text>
                            农业大棚智能温室监测系统通过实时采集农业大棚内空气温度、湿度、光照、土壤温度、土壤水分等环境参数，根据农作物生长需要进行实时智能决策，
                            并自动开启或者关闭指定的环境调节设备。通过该系统的部署实施，可以为农业生态信息自动监测、对设施进行自动控制和智能化管理提供科学依据和有效手段
                        </b-card-text>
                    </b-card>
                </b-card-group>
            </div>
        </div>
        <div>
            <div>
                <b-card-group deck>
                    <b-card :img-src="$store.state.images.scene_01_bsfujk" img-alt="Card image" img-top>
                        <b-card-text>
                        </b-card-text>
                    </b-card>

                    <b-card :img-src="$store.state.images.scene_01_yqktjk" img-alt="Card image" img-bottom>
                        <b-card-text>
                            实时远程监控：通过网络远程对中央空调的压缩机、风机、加热器、加湿器、去湿器、滤网等运行状态实时监控，在中央空调设备实时监控系统中设置温度、湿度、温度上下限、湿度上下限阈值、超限报警，即可实现中央空调设备故障诊断、预警处理及开关机控制.
                        </b-card-text>
                        <b-card-text>
                            数据存储运算：中央空调设备实时监控系统采用时间序列数据库存储,可累计数年宝贵数据不再丢失,数据存储量最高可达1TB，除了元数据累计，还可以自定义加减乘除运算，为进一步的工作流程优化、能效优化提供横向对比，纵向挖掘的数据基础.
                        </b-card-text>
                    </b-card>
                </b-card-group>
            </div>
        </div>


        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: "SuccessfulCase",
        data(){
            return{
            }
        },
        created() {
            var introduce = {
                ptext:"产品广泛应用于工业检测、石油化工、农业现代化、环境监控、电力系统、医疗服务等领域",
                spantext:"PRODUCTS ARE WIDELY USED IN INDUSTRIAL TESTING, PETROCHEMICAL, AGRICULTURAL MODERNIZATION, ENVIRONMENTAL MONITORING, POWER SYSTEM, MEDICAL SERVICES AND OTHER FIELDS",
            }
            this.$emit('Introduce',introduce);
        }
    }
</script>

<style scoped>

</style>