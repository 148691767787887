<template>
    <div>
        <b-carousel
                id="carousel-1"
                v-model="slide"
                :interval="4000"
                controls
                indicators
                background="#ababab"
                img-width="1024"
                img-height="360"
                style="text-shadow: 1px 1px 2px #333;"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
        >
            <b-carousel-slide img-src="https://picsum.photos/1024/360/?image=54">
                <h1>百旺物联网云</h1>
            </b-carousel-slide>
            <!-- Slides with image only -->
            <b-carousel-slide img-src="https://picsum.photos/1024/360/?image=58"></b-carousel-slide>
        </b-carousel>
        <div>
            <b-card-group deck>
                <b-card title="" :img-src="$store.state.images.publicize_01_6" img-alt="Image" img-top >
                    <b-card-text>
                    </b-card-text>
                </b-card>
                <b-card title="" :img-src="$store.state.images.publicize_01_7" img-alt="Image" img-top >
                    <b-card-text>
                    </b-card-text>
                </b-card>
            </b-card-group>
        </div>
        <hr>
        <h3 style="text-align: center">百旺云AIOT全栈云服务</h3>
        <b-card-group>
            <b-card
                    title="物联网云平台"
                    :img-src="$store.state.images.material_baiwangjs"
                    img-alt="Image"
                    img-top
                    tag="article"
                    style="max-width: 20rem;"
                    class="mb-2"
            >
                <b-card-text>
                    多设备接入，多协议支持
                </b-card-text>

                <b-button href="#" variant="primary">>>>了解更多</b-button>
            </b-card>


            <b-card
                    title="数据分析"
                    :img-src="$store.state.images.material_zhuye"
                    img-alt="Image"
                    img-top
                    tag="article"
                    style="max-width: 20rem;"
                    class="mb-2"
            >
                <b-card-text>
                    各种图标数据、监控数据、告警数据展示等
                </b-card-text>

                <b-button href="#" variant="primary">>>>了解更多</b-button>
            </b-card>
            <b-card
                    title="能源统计管理"
                    :img-src="$store.state.images.material_ngjk"
                    img-alt="Image"
                    img-top
                    tag="article"
                    style="max-width: 20rem;"
                    class="mb-2"
            >
                <b-card-text>
                    多电能检测点数据采集，数据统计，用能分析
                </b-card-text>

                <b-button href="#" variant="primary">>>>了解更多</b-button>
            </b-card>
            <b-card
                    title="设备管理"
                    :img-src="$store.state.images.material_wlw"
                    img-alt="Image"
                    img-top
                    tag="article"
                    style="max-width: 20rem;"
                    class="mb-2"
            >
                <b-card-text>
                    设备分组、统计计算
                </b-card-text>

                <b-button href="#" variant="primary">>>>了解更多</b-button>
            </b-card>
        </b-card-group>
        <b-card-group>
            <b-card title="" :img-src="$store.state.images.publicize_03_6" img-alt="Image" img-top >
                <b-card-text>
                </b-card-text>
            </b-card>
        </b-card-group>
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: "IndustryIOT",
        data(){
            return{
                mainProps: { blank: true, blankColor: '#777', width: 75, height: 75, class: 'm1' }
            }
        },
        created() {
            var introduce = {
                ptext:"百旺金穗云物联网整体解决方案",
                spantext:"工业物联网整体方案已经有1000+客户应用，100000+现场安装设备。主要应用于暖通空调、供水、环保、锅炉、供暖、电力、机械设备、农业等行业",
            }
            this.$emit('Introduce',introduce);
        }
    }
</script>

<style scoped>

</style>